<template>
  <BModal
    id="modal-edit-viewer"
    ref="modal-edit-viewer"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    body-class="p-2"
  >
    <div class="flex flex-col gap-4">
      <div class="text-center text-black text-xl font-semibold">
        Edit Penonton
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <div class="flex flex-col gap-1">
          <div class="flex item-center justify-between gap-4">
            <BFormGroup class="w-full">
              <template #label>
                <div class="text-md text-black">
                  Minimal Umur
                </div>
              </template>
              <ValidationProvider
                v-slot="{ errors }"
                name="Minimal Umur"
                :rules="{ required: true, minAgeViewer: 21, maxAgeViewer: 65 }"
              >
                <BFormInput
                  v-model="age.min"
                  autofocus
                  placeholder="Minimal umur"
                  :state="errors.length > 0 ? false : null"
                  @keypress="isNumber($event)"
                  @input="updateMaxAgeRule"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>

            <BFormGroup class="w-full">
              <template #label>
                <div class="text-md text-black">
                  Maksimal Umur
                </div>
              </template>
              <ValidationProvider
                v-slot="{ errors }"
                ref="maxAgeProvider"
                name="Maksimal Umur"
                :rules="{ required: true, maxAgeRule }"
              >
                <div>
                  <BFormInput
                    v-model="age.max"
                    placeholder="Maksimal umur"
                    :state="errors.length > 0 ? false : null"
                    @keypress="isNumber($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </BFormGroup>
          </div>
          <BFormGroup class="w-full">
            <template #label>
              <div class="text-md text-black">
                Jenis Kelamin
              </div>
            </template>
            <div class="flex items-center gap-1">
              <BButton
                v-for="item in listGender"
                :key="item.value"
                :variant="gender === item.value ? 'primary' : 'outline-secondary'"
                class="mr-[5px] flex"
                size="sm"
                @click="selectedGender(item)"
              >
                <span
                  class="font-medium"
                  :class="gender === item.value ? 'text-white' : 'text-black'"
                >{{ item.label }}</span>
              </BButton>
            </div>
          </BFormGroup>
          <BFormGroup class="w-full">
            <template #label>
              <div class="text-md text-black">
                Kategori Bisnis
              </div>
            </template>
            <ValidationProvider
              v-slot="{ errors }"
              name="Kategori Bisnis"
              :rules="{ required: true}"
            >
              <VSelect
                v-model="selectedCategory"
                :options="optionsCategory"
                :reduce="option => option.value"
                :loading="isLoadingCategory"
                :disabled="isLoadingCategory"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>

          <BFormGroup class="w-full">
            <template #label>
              <div class="text-md text-black">
                Lokasi
              </div>
            </template>
            <ValidationProvider
              v-slot="{ errors }"
              name="Lokasi"
              :rules="{ required: true}"
            >
              <VSelect
                v-model="selectedLocation"
                :options="optionsLocations"
                :reduce="option => option.value"
                label="name"
                :loading="isLoadingLocation"
                :disabled="isLoadingLocation"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </div>
        <BRow class="justify-center">
          <BCol cols="6">
            <BButton
              variant="outline-primary"
              block
              size="md"
              type="reset"
              @click="$bvModal.hide('modal-edit-viewer')"
            >
              Batal
            </BButton>
          </BCol>
          <BCol cols="6">
            <BButton
              block
              :variant="invalid || loading ? 'secondary' : 'primary'"
              type="submit"
              :disabled="invalid || loading"
              size="md"
              @click="onFinish"
            >
              <BSpinner
                v-if="loading"
                small
              />
              Simpan
            </BButton>
          </BCol>
        </BRow>
      </ValidationObserver>
    </div>
  </BModal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate'
import { required } from '@validations'
import { isNumber } from '@/libs/helpers'
import { newAxiosIns } from '@/libs/axios'
import { toast_error } from '@/libs/toastification'
import VSelect from 'vue-select'

extend('minAgeViewer', {
  validate: (value, { min }) => value >= min,
  params: ['min'], // Specify the parameter
  message: 'Minimal umur adalah {min}',
})

extend('maxAgeViewer', {
  validate: (value, { max }) => value <= max,
  params: ['max'], // Specify the parameter
  message: 'Maksimal umur adalah {max}',
})

// Extend custom rule for dynamic maxAge validation
extend('maxAgeRule', {
  params: ['minAge'],
  validate: (value, { minAge }) => value >= minAge && value <= 65,
  message: 'Minimal {minAge} dan kurang dari atau sama dengan 65',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
  },
  props: {
    id: { type: Number, default: null },
    age: { type: Object, default: () => {} },
    gender: { type: String, default: '' },
    location: { type: Array, default: () => [] },
    category: { type: Array, default: () => [] },
  },
  data() {
    return {
      required,
      isNumber,
      maxAgeRule: { minAge: 21 }, // default value
      isLoadingCategory: false,
      isLoadingLocation: false,
      isLoadingSubmit: false,
      isLoadingEstimate: false,
      optionsCategory: [],
      optionsLocations: [],
      localCategory: this.category ? this.category[0].value : '',
      localLocation: this.location ? this.location[0].value : '',
    }
  },
  computed: {
    listGender() {
      return [
        { value: 'all', label: 'Semua' },
        { value: 'male', label: 'Laki-laki' },
        { value: 'female', label: 'Perempuan' },
      ]
    },
    selectedCategory: {
      get() {
        return this.localCategory
      },
      set(value) {
        this.localCategory = value
      },
    },
    selectedLocation: {
      get() {
        return this.localLocation
      },
      set(value) {
        this.localLocation = value
      },
    },
    loading() {
      return this.isLoadingSubmit || this.isLoadingEstimate
    },
  },
  watch: {
    category(newVal) {
      if (newVal && newVal.length > 0) {
        this.localCategory = newVal[0].value
      }
    },
    location(newVal) {
      if (newVal && newVal.length > 0) {
        this.localLocation = newVal[0].value
      }
    },
  },
  mounted() {
    this.getCateogryBussiness()
    this.getLocations()
  },
  methods: {
    async getCateogryBussiness() {
      this.isLoadingCategory = true
      const url = '/komads/api/v1/categories'
      try {
        const { data: { data } } = await newAxiosIns.get(url)

        const newData = data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        this.optionsCategory = newData
        this.isLoadingCategory = false
      } catch (error) {
        this.isLoadingCategory = false
        toast_error({ title: 'Error', message: 'Gagal mengambil data kategori bisnis' })
      }
    },
    async getLocations() {
      this.isLoadingLocation = true
      const url = '/komads/api/v1/locations'
      try {
        const { data: { data } } = await newAxiosIns.get(url)

        const newData = data.map(item => ({
          value: item.id,
          name: item.name,
        }))

        this.optionsLocations = newData
        this.isLoadingLocation = false
      } catch (error) {
        this.isLoadingLocation = false
        toast_error({ title: 'Error', message: 'Gagal mengambil data lokasi' })
      }
    },
    async onFinish() {
      this.isLoadingEstimate = true
      const url = '/komads/api/v1/ads/reach-estimate-user'
      const payload = {
        age_min: Number(this.age.min),
        age_max: Number(this.age.max),
        gender: this.gender,
        category_id: this.selectedCategory,
        location_ids: [this.selectedLocation],
      }

      await newAxiosIns.post(url, payload)
        .then(async res => {
          const { data } = res.data
          this.isLoadingEstimate = false
          this.isLoadingSubmit = true

          // eslint-disable-next-line no-shadow
          const url = `/komads/api/v1/ads/${this.id}/update`

          // eslint-disable-next-line no-shadow
          const payload = {
            age_min: Number(this.age.min),
            age_max: Number(this.age.max),
            gender: this.gender,
            category_id: this.selectedCategory,
            location_ids: [this.selectedLocation],
            estimate_audience: `${data.lower_estimate_users} - ${data.upper_estimate_users}`,
          }

          await newAxiosIns.post(url, payload)
            .then(() => {
              this.$bvModal.hide('modal-edit-viewer')
              this.isLoadingSubmit = false
              this.sendMessage()
            })
            .catch(err => {
              const { response: { data: { meta: { message } } } } = err

              this.$bvModal.hide('modal-edit-viewer')
              this.isLoadingSubmit = false
              toast_error({ title: 'Error', message })
            })
        })
        .catch(err => {
          const { response: { data: { meta: { message } } } } = err

          this.isLoadingEstimate = false
          toast_error({ title: 'Error', message })
        })
    },
    updateMaxAgeRule() {
      // Update dynamic rule for maxAge based on minAge
      this.maxAgeRule = { minAge: this.minAge || 21 }
      this.$refs.maxAgeProvider.validate()
    },
    selectedGender(item) {
      const { value } = item
      this.gender = value
    },
    sendMessage() {
      const message = { type: 'REFETCH_GET_ADS' }
      const iframe = document.querySelector('#komads-container iframe')
      iframe.contentWindow.postMessage(message, '*')
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
.vs__dropdown-menu {
  max-height: 230px;
}
.vs__deselect {
  display: none !important;
}
.dropdownitem {
  .btn {
    display: flex;
    justify-content: space-between;
  }
}
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }

</style>
